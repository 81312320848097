//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
// import res from "./common/common";

// const wx = require('weixin-js-sdk');
import api from './networking/api'
import common from './common/common'
import './common/previewImage'
import { LocalStorage } from 'quasar'
export default {
  name: 'PlacesDetail',
  data() {
    return {
      fullscreenLoading: false,
      curStore: '',
      userId: '',
      storeID: '',
      storeName: '',
      desc: '',
      stores: [],
      loading: false,
      items: [],
      wxConfigData: null,
      waterImg:'',
      userName:'',
      userMobile:'',
      base64MObile:'',
      waterString:[],
      isValid:true,
      curActivity:{},
      nowater:false
      
    }
  },
  watch: {
    // items: {
    //   deep: true,
    //   handler(n) {
    //     n.map((item,index) =>{
    //       if(item.image && item.image.indexOf('data:image') == -1){
    //         this.imgWatermark(item.image,this.userName,index)
    //       }
    //     })
    //   }
    // }
  },
  methods: {
    imgWatermark (src, text,index) {
      let _this = this
      let img = new Image();
      img.crossOrigin = "anonymous";
      img.src = src
      img.addEventListener('load',async () => {
        let canvas = await _this.imgToCanvas(img);
        let blob = await _this.watermark(canvas, text)
        let htmlImg = await _this.blobToImg(blob)
        _this.$set(_this.items[index],"image",htmlImg.getAttribute('src'))
      })  
    },
    watermark (canvas, text) {
      return new Promise((resolve, reject) => {
        let context = canvas.getContext('2d')
        // 设置填充字号和字体，样式
        let canvasWidth = context.canvas.width
        let canvasHeight = context.canvas.height
        context.font=`${canvasWidth/24}px microsoft yahei`;
        // 旋转45度
        context.rotate(-45 * Math.PI / 180);
        context.fillStyle = 'RGB(255, 255, 255,0.3)';
        
        for(var i = 0;i<12;i++){
          for(var j = 0;j<12;j++){
            context.fillText(text, (canvasWidth/4) * (6 - i) , (canvasHeight/4) * (6 - j)  )
          }
        }
        // 重置当前的变换矩阵为初始态
        context.setTransform(1, 0, 0, 1, 0, 0);
        canvas.toBlob(blob => resolve(blob))
      })
    },
    blobToImg (blob) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.addEventListener('load', () => {
          let img = new Image();
          img.src = reader.result
          img.addEventListener('load', () => resolve(img))
        })
        reader.readAsDataURL(blob)
      })
    },
    imgToCanvas (img) {
      let canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      return canvas
    },
    requestJsSdkConfig: function() {
      let that = this;
      // let agentId = common.config.agentId;
      // let corpId = common.config.corpId;
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios.get(api.getJsSdkConfig, {
        params: {
          agentId,
          corpId,
          url: encodeURIComponent(window.location.href.split('#'))
        }
      })
              .then(function (response) {
                console.log(response.data.data);
                if (common.success(response)) {
                  let data = response.data.data;
                  that.wxConfigData = {nonceStr: data.nonceStr, timestamp: data.timestamp, signature: data.signature, appId: data.appId};
                  that.wxConfig(that.wxConfigData)
                } else {
                  alert('获取JsSdkConfig失败：' + response.data.reason);
                }
              })
              .catch(function (error) {
                console.log(error);
                alert('获取JsSdkConfig失败：' + error);
              })
              .then(function () {

              });
    },
    removeImage: function (item) {
      this.$dialog.confirm({
        message: `此操作将永久删除${item.name}图片, 是否继续?`,
      }).then(() => {
        this.requestRemoveImage(item);
      }).catch(() => {

      });
    },
    requestRemoveImage: function (item) {
      console.log(item);
      let that = this;
      this.axios.post(api.removeImage + `?code=${item.imageCode}&brand=${that.curStore.brand}&actionId=${that.curActivity.id}`)
              .then(function (response) {
                console.log(`?code=${item.imageCode}&brand=${that.curStore.brand}&actionId=${that.curActivity.id}`,response);
                // alert(JSON.stringify(response.data.data));
                if (common.success(response)) {
                  var result = response.data.data;
                  if (result == false) {
                    that.$dialog.alert({
                      message: response.data.msg || '图片删除失败',
                    });
                  } else {
                    item.image = '';
                    that.$store.commit('refreshList', new Date().getTime());
                    that.$dialog.alert({
                      message: '图片删除成功',
                    });
                  }

                } else {
                  that.$dialog.alert({
                    message: response.data && response.data.msg || '图片删除失败',
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                alert(error);
              })
              .then(function () {

              });
    },
    chooseImage: function (item) {
      console.log(item.name);
      var that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original'], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: "normal", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        success: function (res) {
          console.log(res);
          var localIds = res.localIds; // 返回选定照片的本地ID列表，
          var localId = localIds[0];
          that.uploadImage(localId, item);
          // if (that.isIOS()) {
          //   that.showIOSImage(localId, item);
          // } else {
          //   item.image = localId;
          // }
          // andriod中localId可以作为img标签的src属性显示图片；
          // 而在IOS中需通过上面的接口getLocalImgData获取图片base64数据，从而用于img标签的显示
        }
      });
    },
    uploadImage: function (localId, item) {
      var that = this;
      wx.uploadImage({
        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log('serverId:' + serverId);
          that.uploadImageToServer(serverId, item);
        }
      });
    },
    uploadImageToServer: function (serverId, item) {
      let that = this;
          this.$toast.loading({ 
      message: "正在生成图片",
      forbidClick: true,
      duration: 0});
      this.axios.post(api.uploadImage, {
          storeId: this.curStore.storeID,
          mediaId: serverId,
          code: item.code,
          brand: this.curStore.brand,
          actionId:this.curActivity.id
        }
      ).then(function (response) {
                that.$toast.clear();
                console.log('----- uploadImage -----');
                console.log(response);
                if (common.success(response)) {
                  console.log(response);
                  item.image = api.imageHost + "/" + response.data.data.image;
                  item.imageCode = response.data.data.id;
                  that.$store.commit('refreshList', new Date().getTime());
                  // that.$dialog.alert({
                  //   message: '上传图片成功',
                  // });
                } else {
                  that.$dialog.alert({
                    message:response.data.msg ||  response.data.reason,
                  });
                }
                console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
    },
    previewImage: function (url) {
      if(/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)){
        window.previewImage.start({
          current: url, // 当前显示图片的http链接
          urls: [url] // 需要预览的图片http链接列表
        });
      }else{
        wx.previewImage({
          current: url, // 当前显示图片的http链接
          urls: [url] // 需要预览的图片http链接列表
        });
      }
    },
    showIOSImage: function (localId, item) {
      var _this = this
      wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (res) {
          var localData = res.localData; // localData是图片的base64数据，可以用img标签显示
          item.image = localData
        }
      });
    },
    compressImage: function (base64) {
      //var reader = new FileReader();
      var img = new Image();
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');

// base64地址图片加载完毕后
      img.onload = function () {
        // 图片原始尺寸
        var originWidth = this.width;
        var originHeight = this.height;
        // 最大尺寸限制
        var maxWidth = 400, maxHeight = 400;
        // 目标尺寸
        var targetWidth = originWidth, targetHeight = originHeight;
        // 图片尺寸超过400x400的限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // 更宽，按照宽度限定尺寸
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }

        // canvas对图片进行缩放
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // 清除画布
        context.clearRect(0, 0, targetWidth, targetHeight);
        // 图片压缩
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        // canvas转为blob并上传
        var base64 = canvas.toDataURL('image/jpeg');
        // canvas.toBlob(function (blob) {
        //     // 图片ajax上传
        //     var xhr = new XMLHttpRequest();
        //     // 文件上传成功
        //     xhr.onreadystatechange = function() {
        //         if (xhr.status == 200) {
        //             // xhr.responseText就是返回的数据
        //         }
        //     };
        //     // 开始上传
        //     xhr.open("POST", 'upload.php', true);
        //     xhr.send(blob);
        // }, file.type || 'image/png');
      };
      img.src = base64;
// 文件base64化，以便获知图片原始尺寸
//             reader.onload = function(e) {
//                 img.src = e.target.result;
//             };
    },
    wxConfig: function(data) {
      wx.config({
        beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ['chooseImage', 'uploadImage', 'previewImage', 'getLocalImgData','downloadImage'] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        console.log('reday');
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });

      wx.error(function (res) {
        console.log(res);
        alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
    isIOS: function () {
      if (/(Android)/i.test(window.navigator.userAgent)) {
        return false;
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        return true;
      }
    },
    ossWaterMark(){
          this.userName = LocalStorage.getItem ('userName') || '报喜鸟';
    this.userMobile=LocalStorage.getItem ('userMobile') ||''
    console.log('this.userMobile',this.userMobile)
    if(this.userMobile){
      this.base64MObile=window.btoa(this.userMobile);
      this.nowater=false;
    }else{
      this.nowater=true;
      this.base64MObile=window.btoa(LocalStorage.getItem ('hrid'));
    }
   
      this.waterString[0]='/watermark,type_d3F5LXplbmhlaQ,size_20,text_'+this.base64MObile+',color_FFFFFF,shadow_10,t_50,g_north,x_10,y_10,rotate_45'
      this.waterString[1]='/watermark,type_d3F5LXplbmhlaQ,size_20,text_'+this.base64MObile+',color_FFFFFF,shadow_10,t_50,g_west,x_10,y_10,rotate_45'
      this.waterString[2]='/watermark,type_d3F5LXplbmhlaQ,size_20,text_'+this.base64MObile+',color_FFFFFF,shadow_10,t_50,g_east,x_10,y_10,rotate_45'
       this.waterString[3]='/watermark,type_d3F5LXplbmhlaQ,size_20,text_'+this.base64MObile+',color_FFFFFF,shadow_10,t_50,g_south,x_10,y_10,rotate_45'
       
    }
  }, 
                
  mounted: function () {

    this.ossWaterMark()
    let params = LocalStorage.getItem('params');
    if (params) {
      params = JSON.parse(params);
      //this.wxConfigData = params.wxConfigData;
      this.curStore = params.curStore;
      this.storeName = this.curStore.name;
      this.storeID = this.curStore.storeID;
      this.curActivity = params.curActivity
      this.assessStatus = params.assessStatus
      // if(params.places){
      //   params.places.map((item,index) =>{
      //     if(item.image && item.image.indexOf('data:image') == -1){
      //       this.imgWatermark(item.image,this.userName,index)
      //     }
      //   })
      // }
      this.items = params.places;
      console.log('this.items',this.items,params)
      this.desc = params.desc;
      if(this.$moment(params.curActivity.beginDate,"YYYY-MM-DD HH:mm:ss") <= this.$moment() && this.$moment() <=this.$moment(params.curActivity.endDate,"YYYY-MM-DD HH:mm:ss")){
        this.isValid = true
      }else{
        this.isValid = false
      }
    }

    this.requestJsSdkConfig()

    // if (!this.wxConfigData) {
    //   alert('wxConfigData 为空');
    //   return;
    // }
    //this.wxConfig(this.wxConfigData);
  }
}
